import React, { useState } from "react"
import StandardLayout from "../layouts/standard"
import coverImage from "../images/about/our-mission/our-mission-cover.jpg"
import PageCover from "../components/page-cover"
import up from "../images/downloads/up-arrow.svg"
import down from "../images/downloads/down-arrow.svg"
import pdf from "../images/downloads/pdf.svg"
import surface from "../images/downloads/surface.png"

const data = [
  {
    title: "Laminates",
    image: "https://stylam-upload.s3.amazonaws.com/Laminates_04adfc371e.jpg",
    children: [
      {
        title: "Decorative Laminates",
        documents: [
          {
            title: "Anti Covid Sars-Cov2 Analysis Test Report",
            link:
              "https://stylam-upload.s3.amazonaws.com/Anti_Covid_Sars_Cov2_Analysis_Test_Report_7ce70eaf08.pdf",
          },
          {
            title: "Anti-Bacterial Test- India",
            link:
              "https://stylam-upload.s3.amazonaws.com/Anti_Bacterial_Test_India_f0bc563d64.pdf",
          },
          {
            title: "Anti-Viral HPL Test Report 2021",
            link:
              "https://stylam-upload.s3.amazonaws.com/Anti_Viral_HPL_Test_Report_2021_08b8db96aa.pdf",
          },
          {
            title: "HPL Report Heavy Metals+Toluene",
            link:
              "https://stylam-upload.s3.amazonaws.com/HPL_Report_Heavy_Metals_Toluene_cf05009200.pdf",
          },
          {
            title: "Material Safety",
            link:
              "https://stylam-upload.s3.amazonaws.com/material_safety_b08117f741.pdf",
          },
          {
            title: "MSDS - Laminates",
            link:
              "https://stylam-upload.s3.amazonaws.com/MSDS_Laminates_a2364a43d2.pdf",
          },
          {
            title: "Processing Guidelines-HPL",
            link:
              "https://stylam-upload.s3.amazonaws.com/Processing_Guidelines_HPL_6b0ae5c979.pdf",
          },
          {
            title: "Technical-Datasheet-HPL",
            link:
              "https://stylam-upload.s3.amazonaws.com/Technical_Datasheet_HPL_a4250c03cc.PDF",
          },
        ],
      },
      {
        title: "Post Forming Laminates",
        documents: [
          {
            title: "MSDS - Laminates",
            link:
              "https://stylam-upload.s3.amazonaws.com/MSDS_Laminates_9a07747aad.pdf",
          },
          {
            title: "MSDS_Post_Forming_Laminates",
            link:
              "https://stylam-upload.s3.amazonaws.com/MSDS_Post_Forming_Laminates_434b9c4dd6.pdf",
          },
          {
            title: "Processing Guidelines-PostForm HPL",
            link:
              "https://stylam-upload.s3.amazonaws.com/Processing_Guidelines_Post_Form_HPL_f75c06be1d.pdf",
          },
          {
            title: "Technical-Datasheet-ESD-HPL",
            link:
              "https://stylam-upload.s3.amazonaws.com/Technical_Datasheet_ESD_HPL_a174fecfe9.pdf",
          },
        ],
      },
    ],
  },
  {
    title: "Special Laminates",
    image: "https://stylam-upload.s3.amazonaws.com/special_laminates_0ba868f867.jpg",
    children: [
      {
        title: "Synchro Laminates",
        documents: [
          {
            title: "MSDS_Post_Forming_Laminates",
            link:
              "https://stylam-upload.s3.amazonaws.com/MSDS_Post_Forming_Laminates_568f6c0f22.pdf",
          },
          {
            title: "post_forming_file_processing",
            link:
              "https://stylam-upload.s3.amazonaws.com/post_forming_file_processing_344208ad36.PDF",
          },
          {
            title: "Technical-Datasheet-HPL",
            link:
              "https://stylam-upload.s3.amazonaws.com/Technical_Datasheet_HPL_ce090b8687.pdf",
          },
        ],
      },
      {
        title: "Digital Laminates",
        documents: [
          {
            title: "Technical-Datasheet-HPL",
            link:
              "https://stylam-upload.s3.amazonaws.com/Technical_Datasheet_HPL_1_22df6785e6.pdf",
          },
        ],
      },
      {
        title: "Mirror Shield Laminate",
        documents: [
          {
            title: "Technical-Datasheet-HPL",
            link:
              "https://stylam-upload.s3.amazonaws.com/Technical_Datasheet_HPL_1_22df6785e6.pdf",
          },
        ],
      },
      {
        title: "Metallic Laminates",
        documents: [
          {
            title: "Feron",
            link: "https://stylam-upload.s3.amazonaws.com/Feron_b3f358b3b8.pdf",
          },
        ],
      },
      {
        title: "Chalk/Marker Board",
        documents: [
          {
            title: "Technical-Datasheet-HPL",
            link:
              "https://stylam-upload.s3.amazonaws.com/Technical_Datasheet_HPL_1_22df6785e6.pdf",
          },
        ],
      },
      {
        title: "Unicore Laminates",
        documents: [
          {
            title: "Technical-Datasheet-HPL",
            link:
              "https://stylam-upload.s3.amazonaws.com/Technical_Datasheet_HPL_1_22df6785e6.pdf",
          },
        ],
      },
      {
        title: "Magnetic Laminates",
        documents: [
          {
            title: "Technical-Datasheet-HPL",
            link:
              "https://stylam-upload.s3.amazonaws.com/Technical_Datasheet_HPL_1_22df6785e6.pdf",
          },
        ],
      },
      {
        title: "Flicker Laminates",
        documents: [
          {
            title: "Technical-Datasheet-HPL",
            link:
              "https://stylam-upload.s3.amazonaws.com/Technical_Datasheet_HPL_1_22df6785e6.pdf",
          },
        ],
      },
    ],
  },
  {
    title: "Compact Laminates",
    image: "https://stylam-upload.s3.amazonaws.com/Compact_Laminates_9011f5f2f2.jpg",
    children: [
      {
        title: "Restroom Cubicles & Lockers - Cuboid",
        documents: [
          {
            title: "Compact_Handling_Guidelines",
            link:
              "https://stylam-upload.s3.amazonaws.com/Compact_Handling_Guidelines_4d80d65e6c.pdf",
          },
          {
            title: "Compact-12mm",
            link:
              "https://stylam-upload.s3.amazonaws.com/Compact_12mm_12fdf84424.pdf",
          },
          {
            title: "Compact-18mm",
            link:
              "https://stylam-upload.s3.amazonaws.com/Compact_18mm_87b5f8025e.pdf",
          },
          {
            title: "MSDS_Compact_Laminates",
            link:
              "https://stylam-upload.s3.amazonaws.com/MSDS_Compact_Laminates_c79914c408.pdf",
          },
          {
            title: "Test+Report-EN438-4",
            link:
              "https://stylam-upload.s3.amazonaws.com/Test_Report_EN_438_4_ca0d6893b7.pdf",
          },
        ],
      },
      {
        title: "Exterior Cladding - Fascia",
        documents: [
          {
            title: "Fascia-Installation-Guide",
            link:
              "https://stylam-upload.s3.amazonaws.com/Fascia_Installation_Guide_34219c0e69.pdf",
          },
          {
            title: "Technical-Sheets-Fascia",
            link:
              "https://stylam-upload.s3.amazonaws.com/Technical_Sheets_Fascia_74bf582d64.pdf",
          },
        ],
      },
      {
        title: "Industrial Laminates",
        documents: [
          {
            title: "MSDS_Industrial_Laminates",
            link:
              "https://stylam-upload.s3.amazonaws.com/MSDS_Industrial_Laminates_bda7b5dd7d.pdf",
          },
          {
            title: "TDS_Industrial_Laminates",
            link:
              "https://stylam-upload.s3.amazonaws.com/TDS_Industrial_Laminates_5899d07fdf.PDF",
          },
        ],
      },
    ],
  },
  {
    title: "Luxe Laminates",
    image: "https://stylam-upload.s3.amazonaws.com/Luxe_Laminates_6a79dc856a.jpg",
    children: [
      {
        title: "Anti-Finger Print Laminate - Touch Me",
        documents: [
          {
            title: "Anti-finger+HPL-Sept2020",
            link:
              "https://stylam-upload.s3.amazonaws.com/Anti_finger_HPL_Sept2020_e2d58f5708.pdf",
          },
          {
            title: "Processing Guidelines-TouchMe HPL",
            link:
              "https://stylam-upload.s3.amazonaws.com/Processing_Guidelines_Touch_Me_HPL_635a040e7f.pdf",
          },
          {
            title: "Technical-Datasheet-Anti-Finger-HPL",
            link:
              "https://stylam-upload.s3.amazonaws.com/Technical_Datasheet_Anti_Finger_HPL_84caa757e6.pdf",
          },
        ],
      },
      {
        title: "High Gloss Laminate - Gloss Pro+",
        documents: [
          {
            title: "Technical-Datasheet-GlossPro-HPL",
            link:
              "https://stylam-upload.s3.amazonaws.com/Technical_Datasheet_Gloss_Pro_HPL_ca46a2079b.pdf",
          },
        ],
      },
      {
        title: "Touch Me & Gloss Pro+ Prelam Boards Laminate",
        documents: [
          {
            title: "afp prelam",
            link:
              "https://stylam-upload.s3.amazonaws.com/afp_prelam_8d517127fa.pdf",
          },
          {
            title: "gloss prelam",
            link:
              "https://stylam-upload.s3.amazonaws.com/gloss_prelam_4b339f05c7.pdf",
          },
        ],
      },
    ],
  },
  {
    title: "Solid Surface",
    image: "https://stylam-upload.s3.amazonaws.com/Solid_Surface_61dfd7fc9a.jpg",
    children: [
      {
        title: "Acrylic Solid Surface - Granex",
        documents: [
          {
            title: "Acrylic Anti-Bacteria Test Report 2021",
            link:
              "https://stylam-upload.s3.amazonaws.com/Acrylic_Anti_Bacteria_Test_Report_2021_e2ed8ef5ba.pdf",
          },
          {
            title: "granex_file_processing",
            link:
              "https://stylam-upload.s3.amazonaws.com/granex_file_processing_ec6aa012a0.pdf",
          },
          {
            title: "GRANEX-TDS",
            link:
              "https://stylam-upload.s3.amazonaws.com/GRANEX_TDS_3ac64926ac.pdf",
          },
          {
            title: "Granex+GREENGUARD+Certification",
            link:
              "https://stylam-upload.s3.amazonaws.com/Granex_GREENGUARD_Certification_f011b8e517.pdf",
          },
          {
            title: "Granex+GREENGUARD+Gold+Certification",
            link:
              "https://stylam-upload.s3.amazonaws.com/Granex_GREENGUARD_Gold_Certification_488bc1e5fb.pdf",
          },
          {
            title: "NSF C0563192 - 051 certification",
            link:
              "https://stylam-upload.s3.amazonaws.com/NSF_C0563192_051_certification_992807581b.pdf",
          },
        ],
      },
      {
        title: "Modified Solid Surface - Marvella",
        documents: [
          {
            title: "Acrylic Anti-Bacteria Test Report 2021",
            link:
              "https://stylam-upload.s3.amazonaws.com/Acrylic_Anti_Bacteria_Test_Report_2021_6dd92b2638.pdf",
          },
          {
            title: "Granex GREENGUARD Certification",
            link:
              "https://stylam-upload.s3.amazonaws.com/Granex_GREENGUARD_Certification_f011b8e517.pdf",
          },
          {
            title: "Granex GREENGUARD Gold Certification",
            link:
              "https://stylam-upload.s3.amazonaws.com/Granex_GREENGUARD_Gold_Certification_488bc1e5fb.pdf",
          },
          {
            title: "Modified-Solid-Surface-Marvella_Technical-Specs",
            link:
              "https://stylam-upload.s3.amazonaws.com/Modified_Solid_Surface_Marvella_Technical_Specs_d54f9983d8.pdf",
          },
        ],
      },
    ],
  },
  {
    title: "Performance Laminates",
    image: "https://stylam-upload.s3.amazonaws.com/Performance_Laminates_8b2f5ca134.jpg",
    children: [
      {
        title: "Electrostatic Laminates",
        documents: [
          {
            title: "Technical-Datasheet-ESD-HPL",
            link:
              "https://stylam-upload.s3.amazonaws.com/Technical_Datasheet_HPL_1_22df6785e6.pdf",
          },
        ],
      },
      {
        title: "Chemical Resistant Laminates",
        documents: [
          {
            title: "EPH-2716175_Chemical Resistance_Ber_engl",
            link:
              "https://stylam-upload.s3.amazonaws.com/EPH_2716175_Chemical_Resistance_Ber_engl_30e15731c4.pdf",
          },
          {
            title: "Technical-Sheets-Lab-Grade-Compacts",
            link:
              "https://stylam-upload.s3.amazonaws.com/Technical_Sheets_Lab_Grade_Compacts_db01de32d7.pdf",
          },
        ],
      },
      {
        title: "Fire Retardent Grade Laminates",
        documents: [
          {
            title: "FR Grade Certificate - Ukraine Standards",
            link:
              "https://stylam-upload.s3.amazonaws.com/FR_Grade_Certificate_Ukraine_Standards_0d508a826d.pdf",
          },
          {
            title: "MSDS_Fire_Retardent_Compact",
            link:
              "https://stylam-upload.s3.amazonaws.com/MSDS_Fire_Retardent_Compact_7b56a4b5d0.pdf",
          },
          {
            title: "Technical-Sheets-FR-Compacts",
            link:
              "https://stylam-upload.s3.amazonaws.com/Technical_Sheets_FR_Compacts_6328dd01b5.pdf",
          },
        ],
      },
    ],
  },
]

export default function Downloads(props) {
  const [active, setActive] = useState(0)
  const [activeChild, setActiveChild] = useState([-1, -1])
  const [renderImage, setRenderImage] = useState(true)
  return (
    // <img src={videos} width="100%" />

    <StandardLayout title="Documents">
      <div className="cover-cont">
        <img src={coverImage} alt="" />
        <div>Product Certifications</div>
      </div>
      <div className="catalog-head">Product Certifications</div>
      <section className="uk-section">
        <div className="uk-container uk-margin">
          <div className="cert-cont">
            <div>
              <ul className="no-marker no-padding-sm">
                {data.map((item, index) => (
                  <li>
                    <div
                      className={`${
                        active == index
                          ? "certification-list-active"
                          : "certification-list"
                      }`}
                      onClick={() => {
                        setActiveChild([-1, -1])
                        setActive(index)
                        setRenderImage(true)
                      }}
                    >
                      <span>•</span>
                      <span>{item.title}</span>
                      <span style={{ marginLeft: "auto" }}>
                        {active === index ? (
                          <img src={up} />
                        ) : (
                          <img src={down} />
                        )}
                      </span>
                    </div>
                    {active === index && (
                      <ul className="type-children no-marker">
                        {item.children.map((child, childIndex) => (
                          <li>
                            <div
                              className={`${
                                JSON.stringify(activeChild) ===
                                JSON.stringify([index, childIndex])
                                  ? "certification-list-child-active"
                                  : "certification-list-child"
                              }`}
                            >
                              <span>{">"}</span>
                              <span style={{ marginLeft: "15px" }}>
                                {child.title}
                              </span>
                              <button
                                onClick={() => {
                                  setRenderImage(false)
                                  setActiveChild([index, childIndex])
                                }}
                                className={`${
                                  JSON.stringify(activeChild) ===
                                  JSON.stringify([index, childIndex])
                                    ? "cert-child-btn-active"
                                    : "cert-child-btn"
                                }`}
                              >
                                Product certification
                              </button>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div
              className="docs-cont"
              style={
                renderImage ? { background: `url(${data[active]?.image})` } : {}
              }
            >
              {!renderImage ? (
                <RenderDocs
                  data={data[activeChild[0]].children[activeChild[1]].documents}
                />
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </StandardLayout>
  )
}

function RenderDocs({ data }) {
  return (
    <ul className="no-marker doc-overflow">
      {data.map((item, index) => (
        <li key={`doc-${index}`}>
          <a target="_blank" href={item.link} className="doc-item">
            <img src={pdf} />
            <span className="doc-name">{item.title}</span>
          </a>
        </li>
      ))}
    </ul>
  )
}
